.attemptInfo{
  width: 100%;

  border-radius: 10px;
  background-color: white;

  box-shadow: 0px 0px 4px 1px rgba(0, 29, 51, 0.2);

  overflow: hidden;

  @extend .mt-3;

  .attemptTopBar {
    display: flex;

    .attemptTitle {
      background: #004980;
      color: white;
      font-weight: 600;
      font-size: 24px;
      width: 100%;

      padding: 10px 20px;

      display: flex;
      align-items: center;

      svg {
        margin-right: 12px;
      }

      span {
        font-size: 24px;
      }
    }

    .attemptScore {
      background-color: #ffcc80;
      color: #374047;
      font-weight: 600;

      font-size: 24px;

      display: flex;
      justify-content: center;
      align-items: center;

      padding: 0 20px;

      margin-left: 5px;

      input {
        width: 60px;
        text-align: center;
        background-color: #ffcc80;
        border: 0;
      }

      &.green {
        background-color: #c2ecbf;

        input {
          background-color: #c2ecbf;
        }
      }
    }
  }

  .attemptExercises {
    padding: 6px 18px;

    .exercisesLine {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      column-gap: 12px;
      row-gap: 15px;

      margin: 16px 0;

      width: 100%;

      .exerciseItem {
        border-radius: 4px;
        border-style: none;

        display: flex;
        justify-content: space-between;
        align-items: center;

        padding-left: 16px;
        padding-right: 0px;

        text-decoration: none;

        cursor: pointer;

        overflow: hidden;

        background-color: #ffe0b3;

        .position {
          background-color: $blue-active;
          color: white;
        }

        .title {
          font-size: 16px;
          flex-grow: 1;
          color: #374047;
          padding-top: 16px;
          padding-bottom: 16px;
        }

        .score {
          opacity: 1;
          color: #374047;
          font-weight: 800;
          background-color: #ffcc80;
          padding: 16px 20px;
        }

        .check {
          margin-right: 16px;
        }

        &.answered {
          &.corrected {
            background-color: #ffe0b3 !important;

            .score {
              background-color: #ffcc80;
            }

            &.correct {
              background-color: #dcf4da !important;

              .score {
                background-color: #c2ecbf;
              }
            }

            &.wrong{
              background-color: #ffe0b3 !important;

              .score {
                background-color: #ffcc80;
              }
            }
          }

          &.uncorrected {
            background-color: #e4eff7;

            .score {
              background-color: #bbd7ea;
            }
          }

          &.correct {
            background-color: #dcf4da;

            .score {
              background-color: #c2ecbf;
            }
          }

        }

        &.unanswered {
          background-color: #eceeef;

          .title {
            color: #818a92;
          }

          .score {
            display: none;
          }
        }
      }
    }

    .rememberLine {
      display: flex;
      margin: 32px 8px;
      width: 100%;

      .rememberInfo {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        color: #374047;
        font-size: 16px;

        margin-left: 8px;

        .answeredExercisesCount {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }

}

.attemptInfoLite {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  .item {
    color: #004980;
    font-size: 16px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding: 0 8px 0;

    border-right: 1px solid #004980;

    .title {
      margin-right: 3px;
      font-weight: 600;
    }

    .value {
      font-weight: 400;
    }

    &:first-child {
      padding-left: 0px;
    }

    &:last-child {
      border-right: none;
    }
  }
}