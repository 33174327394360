// Container mixins

@mixin make-container($gutter: $container-padding-x) {
  width: 100%;
  max-width: 100% !important;
  padding-right: var(--#{$variable-prefix}gutter-x, #{$gutter});
  padding-left: var(--#{$variable-prefix}gutter-x, #{$gutter});
  margin-right: auto;
  margin-left: auto;
}
