.resultsInfo {
  width: 100%;

  border-radius: 10px;
  background-color: white;

  box-shadow: 0px 0px 4px 1px rgba(0, 29, 51, 0.2);

  overflow: hidden;

  @extend .mt-3;

  .resultsTitle {
    background: #0d70b6;
    color: white;
    font-weight: 600;
    font-size: 24px;
    width: 100%;

    padding: 10px 20px;
  }

  .resultsExercises {
    padding: 6px 18px;

    .exercisesLine {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      column-gap: 12px;
      row-gap: 15px;

      margin: 16px 0;

      width: 100%;

      .exerciseItem {
        background-color: #eceeef;
        border-radius: 4px;
        border-style: none;

        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 16px 16px;

        text-decoration: none;

        cursor: pointer;

        .title {
          color: #818a92;
          font-size: 16px;

          flex-grow: 1;
        }

        .check {
          margin-right: 16px;
          opacity: 0;

          svg {
            path {
              stroke: #0d70b6 !important;
            }
          }
        }

        &:hover {
        }

        &.answered {
          background-color: #e4eff7;

          .position {
            background-color: $blue-active;
            color: white;
          }

          .title {
            color: #374047;
          }

          .check {
            opacity: 1;
            svg {
              path {
                stroke: $blue-active !important;
              }
            }
          }
        }
      }
    }

    .rememberLine {
      display: flex;
      margin: 32px 8px;
      width: 100%;

      .rememberInfo {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        color: #374047;
        font-size: 16px;

        margin-left: 8px;

        .answeredExercisesCount {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }
}