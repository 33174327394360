.modal-dialog {
  .modal-content {
    background-color: transparent;
    .modal-header{
      background-color: #fff;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border: none;
      margin-bottom: 0!important;
      padding-bottom: 0!important;
      .modal-title.h4{
        color:#374047 !important;
        font-weight:bold;
        font-size: 1.25rem!important;
        padding:1.5rem !important;
        margin-bottom: 0!important;
        padding-bottom: 0!important;
      }
      button.btn-close{
        color:#FFF !important;
        //background-color: #FFF;
        display:none;
      }
    }
    .modal-body{
      background-color: #fff;
      .col-12{
        color:#374047;
        padding: 0 1.5rem !important;
        overflow-wrap:break-word;
        div {
          padding: 0!important;
        }
      }
    }
    .modal-footer{
      //display:none;
      border: none;
      background-color: #fff;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      padding:0 1.5rem 1.5rem 1.5rem !important;
      margin-top: 0!important;
      //padding-top: 0!important;
    }
  }
}

.lesson {

  color:#374047;

  .table {
    border-color: #374047 !important;
    color:#374047;
  }

  .contentBlock-table{
    width: auto;
    max-width: 100%;
    min-width: 400px;

    table-layout: fixed;

    border-color: #AFB4B8 !important;

    border-radius: 10px !important;

    > :not(:first-child){
      border-color: #AFB4B8 !important;
    }

    th, td{
      padding: 10px;
      max-width: 400px;
    }

    thead{
      border-color: #AFB4B8 !important;
      tr{
        background-color: #e4e4e4;
      }
    }
  }

  .btn {
    color: #fff;
    background-color: #0d70b6;
    border-color: #0d70b6;
  }

  .lesson-title {
    color: $colorLetra;

    font-weight: bold;
  }

  .barra-titulo {
    color: $colorLetra;
    background-color: $colorBgTitulo;

    @extend .p-3;
    @extend .ps-4;
    font-weight: bold;
  }

  .cuadroInfo {
    background-color: $colorBgCuadro;
  }

  &.alumno {
    @import "./lesson.alumno";
  }

  &.docente {
    // .imatge {
    //     width: 120px;
    // }

    @import "./lesson.docente";
  }
}

@import "./lesson.aula";
@import "./lesson.exercises";
