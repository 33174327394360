.destacat {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: wrap;
  padding-bottom: 15px;

  @extend .mt-3;
  //@extend .mb-4;

  .item {
    color: #004980;
    font-size: 16px;

    display: flex;
    justify-content: flex-start;
    align-items: center;

    padding: 0 6px 0;

    border-left: 1px solid #004980;

    .title {
      margin-right: 3px;
      font-weight: 600;
    }

    .value {
      font-weight: 400;
    }

    &:first-child {
      padding-left: 0px;
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }
  }

  #competence {
    color: #0d70b6;
    .value {
      font-weight: 600;
    }
  }
}