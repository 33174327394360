.statusBanner{

  border-bottom: 1px solid black;

  display: flex;
  flex-direction: row;

  padding: 1.2rem 2rem .8rem 2rem;

  .icon{
    margin-right: 1rem;

    display: flex;
    justify-content: start;

    svg{
      width: 20px;
      height: 20px;
    }

  }

  .info{

    h3{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      line-height: 20px;
    }

    h6{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
    }

  }

  &.exercise{
    color: #546603;
    background-color: #E9F3B8;
    border-color: #AED017;

    svg path{
      fill: #718806;
    }
  }

  &.activity{
    color: #003B66;
    background-color: #BBD7EA;
    border-color: #428FC6;

    svg path{
      fill: #0D70B6;
    }
  }

  &.test{
    color: #7F004A;
    background-color: #F8E9F2;
    border-color: #E29FC6;

    svg path{
      fill: #D063A3;
    }
  }
}