.slideNoteTeacherComments {
  position: absolute;
  bottom: 66px;
  left: 0;

  width: 100%;
  height: 120px;
  background-color: #e4eff7;

  display: flex;

  padding: 16px 32px;

  border-top: 1px solid #dfe1e3;

  z-index: 1;

  &.asTeacher {
    .additionalComments {
      display: flex;
      flex-direction: column;

      font-weight: bold;

      width: 110px;

      padding: 0 32px 0 0;

      span {
        margin-top: 8px;
      }
    }

    .additionalCommentsReviewed {
      width: 0px;
    }

    .commentsTextArea {
      padding: 0 8px;

      flex-grow: 1;

      textarea {
        width: 100%;
        height: 100%;

        padding: 10px;

        border: 1px solid #dfe1e3;
        border-radius: 8px;
      }
    }

    .scoreTextField {
      width: 84px;

      padding: 0 8px;

      input {
        width: 100%;
        height: 60px;

        text-align: center;

        color: #374047;

        border: 1px solid #dfe1e3;
        border-radius: 8px;
      }
    }

    .gradeInfo {
      width: 140px;
      padding: 0 8px 0 16px;

      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .title {
        font-weight: bold;
        margin-top: 8px;
      }

      .subtitle {
        color: #99a1a7;
        font-size: 12px;
      }
    }
  }

  &.asStudent {
    .wrapper{
      height: 100%;

      display: flex;
      flex-direction: row;

      .icon {
        margin-right: 16px;
      }

      .commentsWrapper {
        margin-top: 1px;

        flex-grow: 1;

        .title {
          color: #0d70b6;
          font-weight: 600;
          font-size: 20px;
        }
        .comments {
        }
      }

      .scoreWrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        height: 100%;

        .score {
          opacity: 1;
          color: #374047;
          font-weight: 800;
          background-color: #ffcc80;
          padding: 24px 24px;

          border-radius: 4px;
          border: 1px solid black;

          &.answered {
            &.correct {
              border-color: #50ca5c;
              background-color: #dcf4da;
            }

            &.wrong {
              border-color: #F9B96A;
              background-color: #ffe0b3;
            }
          }

          &.unanswered {
            border-color: #818a92;
            background-color: #eceeef;
          }

        }
      }
    }
  }
}