.lesson.aula {
  position: relative;

  width: 100%;
  height: 100vh;

  overflow: hidden;

  $blue-active: #0069b7;
  $blue-disabled: #88b9db;

  .container {
    @media (min-width: 1400px) {
      max-width: 80% !important;
    }
  }

  .slideNotesContainer {
    position: relative;

    width: 100%;
    height: 100vh;

    .studentProfileWrapper {
      position: absolute;
      z-index: 200;
      margin: 0;
      padding: 0.5rem;

      display: flex;
      align-items: center;

      .studentProfileName {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 14px;
        color: #374047;
      }
    }

    .slideNotes {
      position: relative;
      padding-top: 80px;

      width: 100%;
      height: 100%;

      .slick-list {
        height: 100% !important;

        .slick-track {
          height: 100% !important;

          .slick-slide {
            height: 100% !important;

            > div {
              height: 100% !important;
            }
          }
        }
      }

      .slideNote {
        width: 100%;
        height: 100% !important;

        .slideNoteWrapper {
          height: calc(100% - 200px) !important;

          display: flex;
          flex-direction: column;

          margin-top: 10px;

          .slideNoteContent {
            flex: 1 1 auto;
            padding: 1rem 3rem 0rem 3rem;

            overflow-y: auto;

            p,
            span {
              font-size: 1.3rem;
            }

            // .col-12{
            //   img{
            //     width: 70%;
            //   }
            // }

            // .col-6 {
            //   img {
            //     width: 100%;
            //   }
            // }
          }
        }
      }

      #initialNote {
        color: white;

        .slideNoteWrapper {
          align-items: center;
          height: 100% !important;

          .slideNoteContent {
            display: flex;

            justify-content: center;
            align-items: center;
            align-content: center;
            justify-items: center;

            text-align: center;
          }

          .empezar {
            border: 1px solid white;
            color: white;

            position: absolute;

            bottom: 130px;
          }

          .slideNoteFooter {
            width: 100%;

            border-top: 1px solid white;

            display: flex;
            justify-content: flex-end;

            padding-top: 20px;
            padding-bottom: 30px;
          }
        }
      }
    }

    .slideNotesBottomMenu {
      position: absolute;
      bottom: 30px;
      left: 0;

      background-color: white;

      width: 100%;

      .navigation {
        width: 100%;

        margin-bottom: 20px;

        .lines {
          position: absolute;
          top: 16px;

          width: 100%;
          height: 2px;

          .line-bg {
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: $blue-disabled;
          }

          .line-progress {
            position: absolute;
            left: 0;

            width: 0%;
            height: 2px;
            background-color: $blue-active;
          }
        }

        .indicator {
          position: absolute;
          top: 30px;
          left: 0;

          color: $blue-active;
          font-weight: bold;
          font-size: 12px;
        }

        .navItems {
          position: relative;

          display: flex;
          justify-content: space-around;

          z-index: 100;

          .navItem {
            display: flex;
            flex-direction: column;
            align-items: center;

            width: 100px;

            .circle {
              width: 32px;
              height: 32px;
              border-radius: 16px;

              font-weight: bold;
              text-decoration: none;

              display: flex;
              justify-content: center;
              align-items: center;

              margin-bottom: 10px;

              cursor: pointer;

              background-color: white;
              border: 1px solid $blue-active;
              color: $blue-active;
            }

            .text {
              color: $blue-active;
              font-size: 12px;
              text-align: center;

              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;

              overflow: hidden;
            }

            &.current {
              .circle {
                background-color: $blue-active;
                border: none;
                color: white;
              }
              .text {
                color: $blue-active;
              }
            }

            &.visited {
              .circle {
                background-color: $blue-disabled;
                border: none;
                color: white;
              }
              .text {
                color: $blue-disabled;
              }
            }

            &.pending {
              .circle {
                background-color: white;
                border: 1px solid $blue-active;
                color: $blue-active;
              }
              .text {
                color: $blue-active;
              }
            }
          }
        }
      }

      .arrows {
        width: 100%;

        display: flex;
        justify-content: center;

        .leftArrow {
          margin-right: 20px;
          cursor: pointer;
        }

        .rightArrow {
          margin-left: 20px;
          cursor: pointer;
        }

        .numSlides {
          color: $blue-active;
        }
      }

      .copyright {
        position: absolute;
        bottom: 0;
        right: 0;

        color: #8a8a8a;
        font-size: 13px;
      }
    }

    .slideNotesHeader {
      position: absolute;
      top: 30px;
      left: 0;

      background-color: white;

      width: 100%;

      display: flex;
      justify-content: space-between;

      padding: 1rem 1rem;

      @extend .h4;
      @extend .text-primary;
      @extend .bold;

      border-bottom: 2px solid $primary;

      &.white {
        color: white !important;
        border-color: white;

        background-color: $blue-active;
      }
    }
  }

  .slideNotesTopMenu {
    position: absolute;
    top: 0;
    right: 0;

    width: 100%;

    display: flex;
    justify-content: flex-end;

    padding: 5px 5px 0 0;

    a {
      padding: 5px;
      cursor: pointer;

      svg {
        fill: $primary;

        path {
          fill: $primary;
        }
      }
    }

    &.white {
      svg {
        fill: white;

        path {
          fill: white;
        }
      }
    }
  }

  .sidebar {
    z-index: 200;

    position: absolute;
    top: 0;
    right: 0;

    width: 350px;
    height: 100%;

    background-color: white;

    @extend .shadow;

    display: flex;
    flex-direction: column;

    .sidebarHeader {
      margin-top: 30px;

      width: 100%;

      padding: 1rem 1rem;

      @extend .h4;
      @extend .text-primary;
      @extend .bold;

      color: #21272b !important;

      border-bottom: 2px solid #b0b0b0;
    }

    .sidebarContent {
      padding: 1rem 1rem;
    }

    .closeButton {
      position: absolute;
      top: 10px;
      right: 10px;

      cursor: pointer;
    }
  }
}
