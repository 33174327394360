#initialNote {

  .attemptInfo {

    .attemptExercises {
      padding: 0px 12px;

      .exercisesLine {

        .exerciseItem {

          .check {
            margin-right: 16px;
            opacity: 0;

            svg {
              path {
                stroke: #0d70b6 !important;
              }
            }
          }

          &:hover {
          }

          &.answered {
            background-color: #e4eff7;

            .position {
              background-color: $blue-active;
              color: white;
            }

            .title {
              color: #374047;
            }

            .check {
              opacity: 1;
              svg {
                path {
                  stroke: $blue-active !important;
                }
              }
            }
          }
        }
      }

    }
  }

}