//fuente
$font-family: 'Roboto', sans-serif;
$font-weight: 400;

@font-face {
    font-family: 'Roboto';
    src: local("Roboto"),
    url(./Roboto-Regular.ttf);
}

// colors
$colorLetra: #0D70B6;
$colorBgTitulo: #E4EFF7;
$colorBgCuadro: #F9F9FA;
