#counterLayer {
  z-index: 200;

  position: absolute;
  right: 24px;
  top: 24px;

  width: 105px;
  height: 45px;

  background-color: #e4eff7;

  border-radius: 7px;

  display: flex;
  justify-content: center;
  align-items: center;

  &.visible {
    display: flex;
  }

  &.hidden {
    display: none;
  }

  .numbers {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 800;
    font-size: 28px;

    .minutes {
      color: #003b66;
    }

    .dots {
      color: #003b66;
    }

    .seconds {
      color: #0d70b6;
    }
  }
}