.slideNotesBottomMenu {
  position: absolute;
  bottom: 0;
  left: 0;

  background-color: white;

  width: 100%;
  min-height: 66px;

  display: flex;
  flex-direction: column;

  .navigation {
    width: 100%;

    margin-bottom: 12px;

    .lines {
      width: 100%;
      height: 2px;

      .line-bg {
        position: absolute;
        width: 100%;
        height: 4px;
        background-color: #d0d3d6;
      }

      .line-progress {
        position: absolute;
        left: 0;

        width: 0%;
        height: 4px;
        background-color: $blue-active;
      }
    }
  }

  .visible {
    display: flex !important;
  }

  .hidden {
    display: none !important;
  }

  .button {
    cursor: pointer;
    height: 40px;
    border-radius: 4px;
    border-style: none;

    display: flex;
    align-items: center;

    text-decoration: none !important;

    background-color: $blue-active;
    color: white;

    padding: 0 24px;

    span {
      text-decoration: none !important;

      font-weight: 600;
      font-size: 16px;
    }

    svg {
    }

    &.white {
      border: 1px solid #d0d3d6;
      background-color: white;
      color: $blue-active;
    }

    &.disabled {
      pointer-events: none !important;
      background-color: #bbd7ea !important;
      color: #e4eff7 !important;

      svg {
        path {
          stroke: #e4eff7 !important;
        }
      }
    }
  }

  .arrows {
    width: 100%;

    margin-bottom: 12px;

    div {
      display: flex;
      justify-content: center;
      align-items: center;

      .leftArrow {
        margin-right: 20px;
        padding-left: 32px;

        svg {
          margin-right: 12px;
        }
      }

      .rightArrow {
        margin-left: 20px;
        padding-right: 32px;

        svg {
          margin-left: 12px;
        }
      }

      .numSlides {
        font-size: 18px;
        font-weight: bold;

        .position {
          color: $blue-active;
        }
        .slash {
          color: #d0d3d6;
        }
        .total {
          color: #d0d3d6;
        }
      }
    }
  }

  .rightButtons {
    position: absolute;
    top: 0;
    right: 0;

    height: 100%;

    div {
      height: 100%;
      display: flex;
      align-items: center;

      .checkAnswersButton,
      .retryExerciseButton,
      .sendActivityButton {
        margin-right: 24px;
      }

      svg {
        margin-left: 12px;
      }
    }
  }

  .leftButtons {
    position: absolute;
    top: 0;
    left: 0;

    height: 100%;

    display: flex;
    align-items: center;

    .resetExerciseButton {
      cursor: pointer;
      height: 40px;
      border-radius: 4px;
      border-style: none;

      display: flex;
      align-items: center;

      text-decoration: none !important;

      margin-left: 24px;
      background-color: $blue-active;

      padding-left: 32px;
      padding-right: 32px;

      color: white;

      span {
        text-decoration: none !important;

        font-weight: 600;
        font-size: 16px;
      }
    }
  }
}