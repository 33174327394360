.sidebar {
  z-index: 200;

  position: absolute;
  top: 0;
  left: -350px;

  width: 350px;
  height: 100%;

  background-color: white;

  .sidebarWrapper {
    position: absolute;
    top: 0;
    left: 0;

    width: 350px;
    height: 100%;

    @extend .shadow;

    display: flex;
    flex-direction: column;

    .sidebarContent {
      padding: 4rem 2rem;

      display: flex;
      flex-direction: column;

      overflow: auto;

      .sidebarLink {
        color: #374047;
        background-color: white;
        text-decoration: none;
        padding: 12px 16px;
        text-align: left;
        cursor: pointer;
        border: none;

        &:hover,
        &.selected {
          background-color: #e4eff7;
        }
      }
    }
  }

  .sidebarSolapa {
    width: 40px;
    height: 40px;

    position: absolute;
    top: 20px;
    left: 350px;

    background-color: $blue-active;

    border-radius: 0px 4px 4px 0px;

    .solapaButton {
      width: 40px;
      height: 40px;
      border-style: none;
      border-radius: 4px;

      display: flex;
      justify-content: center;
      align-items: center;

      background-color: $blue-active;

      cursor: pointer;

      border: none;

      svg.closeIcon {
        display: none;
      }

      svg.menuIcon {
        display: none;
      }
    }
  }
}