.rubric {
  border-radius: 10px;
  background-color: white;
  box-shadow: 0px 0px 4px 1px rgba(0, 29, 51, 0.2);
  overflow: hidden;

  @extend .mt-5;

  .competenceTopBar {
    display: flex;

    .competenceTitle {
      background: #0d70b6;
      color: white;
      font-weight: 600;
      font-size: 24px;
      width: 100%;

      padding: 10px 20px;
    }

    .competenceScore {
      background-color: #ffcc80;
      color: #374047;
      font-weight: 600;

      font-size: 24px;

      display: flex;
      justify-content: center;
      align-items: center;

      padding: 0 20px;

      margin-left: 5px;

      &.green {
        background-color: #c2ecbf;
      }
    }
  }

  .competenceItem {
    padding: 20px;

    border-bottom: 1px solid rgba(0, 29, 51, 0.2);

    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 23px;

    letter-spacing: -0.24px;

    color: #374047;
  }

  .evaluationItem {
    padding: 20px;

    .title {
      font-weight: 600;
      font-size: 20px;
      color: #374047;
    }

    .text {
      margin-top: 8px;

      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;

      letter-spacing: -0.24px;

      color: #374047;
    }
  }

  .rubricTitle {
    background: #bbd7ea;
    color: #374047;
    font-weight: 600;
    font-size: 20px;
    width: 100%;

    padding: 12px 20px;
  }

  .rubricItems {
    display: flex;
    flex-wrap: wrap;

    .rubricItem {
      position: relative;
      width: 50%;
      padding: 16px 32px;
      background-color: #f4fbf3;
      color: #4d2e00;
      border-bottom: 1px solid rgba(0, 29, 51, 0.2);
      border-right: 1px solid rgba(0, 29, 51, 0.2);

      .title {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;

        color: #4d2e00;
      }

      .text {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        display: flex;
        align-items: center;
        letter-spacing: -0.24px;

        color: #4d2e00;

        margin-top: 8px;
      }

      .icon {
        position: absolute;
        top: 12px;
        right: 12px;

        display: none;
        background-color: transparent !important;
      }

      .radio {
        position: absolute;
        top: 12px;
        right: 12px;

        background-color: transparent !important;
      }

      &.n1 {
        background-color: #fff5e6;
      }
    }
  }

  &.solve_retry,
  &.display_with_data,
  &.in_review_first_time,
  &.in_review_continue,
  &.reviewed  {
    .rubricItems {
      .rubricItem {
        &.selected {
          background-color: #c2ecbf;
          .icon {
            display: block;
            background-color: #dcf4da;
            background-color: transparent !important;
          }
        }
        &.n1 {
          &.selected {
            background-color: #ffe0b3;
          }
        }
      }
    }
  }
}