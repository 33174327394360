#overlayLayer {
  z-index: 300;

  position: absolute;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(white, 0.95);

  display: flex;
  justify-content: center;
  align-items: center;

  &.visible {
    display: flex;
  }

  &.hidden {
    display: none;
  }

  .popup {
    width: 500px;
    background-color: white;
    box-shadow: 0px 0px 16px 3px rgba(0, 29, 51, 0.15);
    border-radius: 4px;

    padding: 24px;

    &.visible {
      display: block;
    }

    &.hidden {
      display: none;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;

      .button {
        cursor: pointer;
        border-radius: 4px;
        border-style: none;

        display: flex;
        align-items: center;

        text-decoration: none !important;

        padding: 12px 16px;

        &.blue {
          color: white;
          background-color: $blue-active;
        }

        &.red {
          color: white;
          background-color: #ff4d4d;
        }

        &.white {
          background-color: white;
          border: 1px solid #d0d3d6;
          color: #818a92;
        }

        &:first-child {
          margin-right: 8px;
        }

        span {
          text-decoration: none !important;

          font-weight: 600;
          font-size: 16px;
        }

        svg {
          margin-left: 12px;
        }
      }
    }
  }
}