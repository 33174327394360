$blue-active: #0d70b6;
$blue-disabled: #88b9db;

.modal-help {
  max-width: 100%;
  background: white;
  font-family: "Roboto";
  font-size: 16px;
  color: white;
}

.lesson.exercises {
  position: relative;

  width: 100%;
  height: 100vh;

  overflow: hidden;

  .container {
    font-family: "Roboto";
    @media (min-width: 1400px) {
      max-width: 80% !important;
    }
  }

  .slideNotesContainer {
    position: absolute;
    top: 0;
    left: 0;

    .studentProfileWrapper {
      display: flex;
      align-items: center;

      @extend .pt-2;
      @extend .ps-2;

      .studentProfileImage{
        width: 50px;
        aspect-ratio: 1;
      }

      .studentProfileName {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: #374047;

        margin: 0;
        @extend .ms-2;
      }

      &.inline{

      }

      &.fixed{
        position: absolute;
        z-index: 200;
        margin: 0;

        .studentProfileName{
          opacity: 0;
        }

        &:hover{
          .studentProfileName{
            transition: opacity .5s;
            opacity: 1;
          }
        }
      }
    }

    width: 100%;
    height: 100vh;

    .slideNotes {
      position: relative;

      width: 100%;
      height: 100vh;

      .slideNoteContainer {
        width: 100%;
        height: 100vh;

        padding: 0 !important;
      }

      .slick-list {
        height: 100% !important;

        .slick-track {
          height: 100% !important;

          .slick-slide {
            height: 100% !important;

            > div {
              height: 100% !important;
            }
          }
        }
      }

      @import "lesson.exercises.slideNote";
    }
  }

  @import "lesson.exercises.bottomMenu";
  @import "lesson.exercises.sidebar";

  .loading {
    z-index: 300;

    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    background-color: rgba(white, 0.9);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  @import "lesson.exercises.overlayLayer";
  @import "lesson.exercises.counterLayer";

  .noteContentModalButtonWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 2rem;
    h5 {
      font-size: 1.25rem;
      margin: 0;
    }
    .btn {
      &:focus {
        box-shadow: none;
      }
    }
    svg {
      fill: $blue-active;
      &:hover {
        fill: #0e4d79;
      }
    }
  }

  &.test {
    @import "lesson.exercises.test";
  }
}