.slideNote {
  width: 100%;
  height: 100% !important;

  position: relative;

  &.initialNote, &.summaryNote, &.reviewedNote{
    background-color: #e4eff7;
  }

  .lessonType {
    font-size: 16px;
    font-weight: 600;
    color: #384404;
    padding: 10px 16px;
    border-radius: 4px;

    &.exercise {
      background-color: #dfee99;
    }

    &.activity {
      background-color: #bbd7ea;
    }

    &.test {
      color: #4d002d;
      background-color: #e9b5d3;
    }
  }

  .slideTitle {
    color: #004980;
    font-size: 31px;
    font-weight: 600;
  }

  .lessonInfo {
    margin-left: 16px;

    display: flex;
    flex-direction: column;

    color: $blue-active;

    .unitInfo {
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 600;

      color: #004980;
    }

    .lessonTitle {
      line-height: 24px;

      color: #004980;
      font-size: 31px;
      font-weight: 600;

      margin-top: 10px;

      padding: 0;
    }
  }

  @import "lesson.exercises.slideNote.statusBanner";
  @import "lesson.exercises.slideNote.resultsInfo";
  @import "lesson.exercises.slideNote.attemptInfo";
  @import "lesson.exercises.slideNote.destacat";
  @import "lesson.exercises.slideNote.rubric";
  @import "lesson.exercises.slideNote.teacherComments";

  .slideNoteWrapper {
    height: calc(100% - 66px) !important;

    display: flex;
    flex-direction: column;

    overflow-y: auto;

    //padding-top: 10px;

    .slideNoteContent {
      flex: 1 1 auto;
      padding: 3rem 3rem 3rem 3rem;

      p,
      span {
        font-size: 16px;
      }
    }

    &.additionalPadding {
      padding-bottom: 120px;

      .slideNoteContent {
        padding-bottom: 20px;
      }

      .button {
        cursor: pointer;
        height: 40px;
        border-radius: 4px;
        border-style: none;
        display: flex;
        align-items: center;
        text-decoration: none !important;
        background-color: #0d70b6;
        color: white;
        padding: 0 24px;
      }
    }
  }

  .statement {
    color: #374047;
    font-size: 16px;
    .contentBlock .my-3 {
      margin: 0px !important;
    }

    img {
      margin: 20px;
    }

    margin-bottom: 20px;
  }

  .button {
    cursor: pointer;
    height: 40px;
    border-radius: 4px;
    border-style: none;

    display: flex;
    align-items: center;

    text-decoration: none !important;

    margin-right: 24px;
    background-color: $blue-active;

    padding-left: 32px;
    padding-right: 32px;

    color: white;

    span {
      text-decoration: none !important;

      font-weight: 600;
      font-size: 16px !important;
    }
  }

  .answer {
    &.hide {
      display: none;
    }
  }

  form {
    .digitalExerciseBlock {
      .apartado {
        .contentBlock .my-3 {
          margin-top: 10px !important;
          margin-bottom: 10px !important;
        }
      }

      &.digitalExerciseBlock1 {
        img {
          position: relative;
          top: -20px;
          left: 20px;
          margin: 10px;
        }

        table {
          tr {
            min-height: 80px;
          }
        }
      }

      &.digitalExerciseBlock2 {

        table {
          width: 100%;
          //table-layout: fixed;

          tr {
            td {
              padding-bottom: 30px;

              &.enunciado {
                max-width: 200px;

                >.wrapper{
                  display: flex;
                  flex-direction: row;
                  align-items: center;

                  .contentBlock{
                    &:first-child {
                      margin-right: 10px;
                    }

                    img{
                      max-width: 100%;
                    }
                  }
                }
              }

              &.opciones{
                padding-left: 40px;

                >.wrapper{
                  display: flex;
                  flex-direction: row;
                  flex-wrap: wrap;
                  align-items: center;

                  >div{
                    margin-right: 40px;
                  }
                }
              }
            }
          }
        }
      }

      &.digitalExerciseBlock4 {
        display: flex;
        flex-wrap: wrap;

        .apartado {
          width: 50%;

          margin-bottom: 40px;

          text-align: center;

          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .contentBlock {
            text-align: center;

            // img {
            //   width: 300px;
            //   max-height: 300px;
            // }
          }
        }
      }

      &.digitalExerciseBlock5 {
        table {
          width: 100%;
          table-layout: fixed;
          td {
            margin: 0px;
            vertical-align: middle;
            text-align: center;
          }
          .contentBlock .my-3 div {
            display: block !important;
            text-overflow: ellipsis;
            //cursor: pointer;
            //overflow: hidden;
            //white-space: nowrap;

            overflow: visible;
            white-space: normal;
            //&:hover {
            //  overflow: visible;
            //  white-space: normal;
            //}
          }
          .contentBlock .my-3 input[type="text"] {
            //min-width: 0;
            margin: 0px !important;
            //padding: 0px !important;
          }

        }
      }

      &.digitalExerciseBlock6 {
        table {
          width: 100%;
          table-layout: fixed;

          .apartado {
            img {
              position: relative;
              top: -30px;
              left: 30px;
            }

            td {
              display: flex;
              flex-direction: row;
              align-items: center;

              padding: 10px 0;

              vertical-align: top;

              /*input{
                position:relative;
                top:-30px;
                width: 15px;
                height: 15px;
              }*/

              // label {
              //   .imatge {
              //     height: 100px;
              //   }
              // }
            }
          }
        }
      }

      &.digitalExerciseBlock7 {
        table {
          width: 100%;
        }

        .ck-editor__editable {
          min-height: 200px !important;
        }

        .showAsAnswered {
          .ck-editor__editable {
            background-color: #eceeef !important;
          }
        }
      }

      &.digitalExerciseBlock8 {
        table {
          width: 100%;
        }

        .ck-editor__editable {
          min-height: 200px !important;
        }

        .reactSketchCanvasContainer{
          width: 100%;
          height: 400px;

          .toolbar{
            .btn {
              color: #0d70b6;
              background-color: white;
              border-color: #d0d3d6;

              &:hover{
                border-color: #0d70b6;
              }
            }
          }

          .reactSketchCanvas{
            border: 0.0625rem solid black !important;
            border-radius: 0.25rem !important;
            border-color: rgb(156, 156, 156) !important;
          }

          &.disabled{
            pointer-events: none;

            *{
              pointer-events: none;
            }

            .toolbar{
              display: none;
            }
          }
        }

      }

      input {
        accent-color: $primary;
        //border:2px solid #0f0 !important;
      }

      input[type=text]:focus{
        outline: none !important;
        font-weight: bold;
        //border:2px solid #f00 !important;
      }

      input[type="text"] {
        border: 2px solid #aeb4b8;
        border-radius: 4px;

        padding: 2px 2px;
        margin-top: 15px;
        margin-bottom: 15px;

        font-weight: 400;
        font-size: 16px;

        text-align: center;

        min-width: 60px;

        background-color: white;

        &.correct {
          background-color: #d8f3da;
          border-color: #50ca5c;
        }

        &.wrong {
          background-color: #ffe0b3;
          border-color: #e58a00;
        }

        &.correctAnswer {
          background-color: #eceeef;
          border-color: #eceeef;
          color: #626e77;
        }
      }

      input[type="text"]:focus {
        border-color: #0d70b6 !important;
      }

      .radioElement{
        display: flex;
        flex-direction: row;
        align-items: baseline;

        input[type="radio"] {
          &:checked {
            & + label {
              font-weight: bold;

              .katex {
                font-weight: bold;
              }
            }
          }
          &.correct {
            & + label {
              color: #50ca5c;
              .katex {
                color: #50ca5c;
              }
            }
          }

          &.wrong {
            & + label {
              color: black;
              .katex {
                color: black;
              }
            }
          }
        }

        span.katex{
          //display: block;
          padding: 5px 0;
        }
      }
    }
  }

  .ckEditorAnswer {
    width: 100%;
    min-height: 200px;

    border-radius: 4px;
    background-color: #eceeef;

    padding: 0 16px;

    font-size: 16px;
    color: #626e77;

    margin-top: 40px;
  }

  .openAnswer {
    border-radius: 4px;
    background: #f6fafc;

    font-size: 16px;
    color: #626e77;

    margin-top: 40px;

    .cabecera {
      background: #e4eff7;
      color: #0d70b6;

      padding: 8px 16px;

      display: flex;
      align-items: center;

      svg {
        margin-right: 10px;
      }

      span {
        font-weight: bold;
      }
    }

    .row {
      padding: 0 16px;
    }
  }

}