// @font-face {
//   font-family: 'Arial-BoldMT';
//   src: url('./fonts/Arial-BoldMT.woff2') format('woff2'),
//     url('./fonts/Arial-BoldMT.woff') format('woff');
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'ArialMT';
//   src: url('./fonts/Arial-BoldMT.woff2') format('woff2'),
//   url('./fonts/Arial-BoldMT.woff') format('woff');
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }

@import "libs/bootstrap/bootstrap";

@import "libs/slick/slick";
@import "libs/slick/slick-theme";

//Fuentes
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.9.1/font/bootstrap-icons.css");

//variables
@import "variables";

//components

.katex {
  font-family: "Roboto" !important;
  font-size: 16px !important;
  color: #374047;
}

//img{
//  margin-top: 20px;
//  margin-bottom: 20px;
//}

.card {
  border: none;
}

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic !important;
}

svg.imatge2 {
  color: black !important;

  text {
    color: black !important;
  }
}

.navbar-dark .navbar-nav .nav-link {
  color: #777777;
}

.navbar-dark .navbar-nav .nav-link:hover {
  color: #222222;
}

.navbar-dark .navbar-nav .nav-link:active,
.navbar-dark .navbar-nav .nav-link:visited {
  color: #777777;
}

.lessons {
  table {
    td.title {
      max-width: 400px;
    }
  }
}

@import "./components/lesson.scss";

@import "./components/pdf.scss";
